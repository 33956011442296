<template>
  <p-drawer :steps="step" :is-show-overlay="isShowOverlay" @on-close="smartRouter.back()">
    <template #step1>
      <p-segmented-control
        id="alarmDirection"
        v-model="alarmDirection"
        size="lg"
        :items="alarmDirectionControl"
        @on-change="selectedSegment"
      />
      <div class="place-items-center flex-column gap-24 mt-24">
        <div class="alarm-content">
          <alarm-input
            v-model="triggerPrice"
            :alarm-direction="alarmDirection"
            name="triggerPrice"
            type="text"
            :precision="currentMarket?.precisions?.price"
            :last-price="lastPrice"
            :payment-currency="paymentCurrency"
          >
            <template v-if="alarmDirection === 'price'" #append>
              <p v-text="paymentCurrency" />
            </template>
            <template v-else #prepend>
              <p v-text="'%'" />
            </template>
          </alarm-input>
        </div>

        <p-button size="sm" block="true" button-type="ghost" @click="changeAlarmType">
          <p class="ticker--option--title" v-text="selectedAlarmType"></p>
          <p-icon icon="caret-sort" size="md" color="fg-secondary" />
        </p-button>
        <p-button size="lg" block="true" class="full-width" @click="createAlarm">
          {{ $t('alarm.createAlarm') }}
        </p-button>
      </div>
    </template>
  </p-drawer>
</template>

<script setup lang="ts">
import type { MarketConfig, TickerList } from '~/models'

const route = useRoute()
const alarmType = ref<string>('constant')
const alarmDirection = ref<string>('price')
const triggerPrice = ref<string | number>(0)
const mainStore = useMainStore()

defineProps({
  isShowOverlay: {
    type: Boolean,
    default: true,
  },
})

const alarmDirectionControl = [
  {
    key: 'price',
    display: $t('general.price'),
  },
  {
    key: 'percentage',
    display: $t('general.percentage'),
  },
]

const selectedAlarmType = computed<string>(() => {
  return alarmType.value === 'constant' ? $t('alarm.constant') : $t('alarm.onetime')
})

const changeAlarmType = () => {
  alarmType.value = alarmType.value === 'constant' ? 'onetime' : 'constant'
}

const createAlarm = () => {
  // const alarm = {
  //   alarmType: alarmType.value,
  //   triggerPrice: triggerPrice.value,
  // }
  // todo: create alarm with ${alarm} object
}

const paymentCurrency = computed(() => {
  return markets.value[currentMarket?.value?.id]?.pairs?.payment?.toUpperCase()
})

const tickers = computed((): TickerList => {
  return mainStore.getTickers
})

const markets = computed((): MarketConfig => {
  return mainStore.getAllMarkets
})

const marketList = computed(() => {
  return mainStore.getMarketList
})

const lastPrice = computed(() => {
  return formatNumber({
    num: tickers.value[currentMarket?.value?.id]?.last,
    precision: markets.value[currentMarket?.value?.id]?.precisions?.price,
  })
})

const marketTitle = computed<string>(() => {
  return currentMarket?.value?.id?.replace('_', '/')?.toUpperCase?.() || ''
})

const currentMarket = computed(() => {
  return marketList.value[route.query.market as string] || null
})

const icon = computed(() => {
  return getAsset({ asset: currentMarket?.value?.icon, type: 'coin' })
})

const selectedSegment = () => {
  alarmDirection.value === 'percentage'
    ? (triggerPrice.value = 0)
    : (triggerPrice.value = lastPrice.value)
}

const step = computed(() => ({
  step1: {
    title: marketTitle.value,
    url: 'setAlarm',
    back: true,
    close: true,
    description: `${lastPrice.value} ${paymentCurrency.value}`,
    headerTitleSlot: {
      icon: icon.value,
    },
  },
}))

watch(
  () => lastPrice.value,
  (newValue) => {
    triggerPrice.value = newValue
  },
  { immediate: true },
)
</script>
