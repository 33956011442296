<template>
  <div class="full-width">
    <div
      :class="['alarm-input', alarmType !== 'price' && 'alarm-input__percentage']"
      @click="focusInput(name)"
    >
      <p v-if="infoText" class="f-utility-14-medium t-fg-tertiary" v-text="infoText" />
      <div :class="wrapperClass">
        <span v-if="$slots.prepend" class="alarm-input__prepend">
          <slot name="prepend" />
        </span>
        <input
          v-model="value"
          v-bind="fieldAttributes"
          autocomplete="off"
          required
          @focus="handleFocus"
          @blur="handleBlur(value)"
          @input="sanitizeInput"
          @change="$emit('on-change', value)"
        />
        <span v-if="$slots.append" class="alarm-input__append">
          <slot name="append" />
        </span>
      </div>
      <div :class="['f-utility-14-medium', textColor]">
        <span v-if="alarmType === 'price'" v-text="formattedValue" />
        <span v-else v-text="`≈ ${percentagePrice} ${paymentCurrency}`" />
      </div>
    </div>
    <div v-if="alarmType !== 'price'" class="full-width">
      <p class="f-utility-14 t-fg-tertiary" v-text="$t('alarm.alarmPercentage')" />
      <p-slider v-model="sliderValue" :step="15" class="alarm-input__slider" :range="[-25, 25]" />
      <div class="f-utility-12-medium t-fg-tertiary place-items-between">
        <span
          v-for="info in sliderInfos"
          :key="info"
          v-text="
            `${info < 0 ? '-' : info > 0 ? '+' : ''}${formatPercent({ num: info, precision: 0 })}`
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  lastPrice: {
    type: Number,
    default: 0,
  },
  paymentCurrency: {
    type: String,
    default: '',
  },
  precision: {
    type: Number,
    default: 2,
  },
  alarmDirection: {
    type: String,
    default: 'price',
  },
})

const emit = defineEmits<{
  'on-change': [value: any]
  'on-input': [value: any]
  'on-focus': [value: boolean]
  'on-blur': [value: string | number]
  'update:modelValue': [value: string | number]
}>()

const route = useRoute()
const value = ref(props.modelValue?.toString() || '')
const formattedValue = ref('')
const percentagePrice = ref(props.lastPrice)
const sliderValue = ref(0)
const priceValue = ref('')
const inputCheck = ref<boolean>(false)

const placeholder = computed(() => props.placeholder || '0')

const alarmType = computed(() => (props.alarmDirection === 'price' ? 'price' : 'percentage'))

const inputClass = computed(() => {
  const classes = ['alarm-input__field', 'f-title-48-bold']
  const val = Number(value.value)

  if (val < 0) {
    classes.push('alarm-input__negative')
  } else if (alarmType.value !== 'price' && val > 0) {
    classes.push('alarm-input__positive')
  }

  return classes
})

const inputStyle = computed(() => ({
  width: `${lengthCalc(value.value) || 0}ch`,
  minWidth: lengthCalc(value.value) ? undefined : `${lengthCalc(placeholder.value || 0) || 0}ch`,
}))

const fieldAttributes = computed(() => ({
  name: props.name,
  id: props.name,
  required: true,
  placeholder: placeholder.value,
  class: inputClass.value,
  style: inputStyle.value,
}))

const textColor = computed(() => {
  if (alarmType.value !== 'price') {
    return 't-fg-tertiary'
  }

  const initial = Number(priceValue.value)
  if (initial === 0) {
    return 't-fg-tertiary'
  } else if (initial < 0) {
    return 't-fg-danger'
  } else {
    return 't-fg-accent'
  }
})

const infoText = computed(() => {
  if (props.alarmDirection === 'price') {
    return $t('alarm.priceTarget')
  } else {
    return Number(value.value) >= 0 ? $t('alarm.priceIncrease') : $t('alarm.priceDrop')
  }
})

const wrapperClass = computed(() => {
  return alarmType.value === 'price' ? 'alarm-input__wrapper' : 'alarm-input__percentage--wrapper'
})

const focusInput = (name: string) => {
  const el = document.getElementById(name)
  if (el) el.focus()
}

const handleFocus = () => emit('on-focus', true)
const handleBlur = (value: string | number) => emit('on-blur', value)

const sliderInfos = [-25, 0, +25]

const parseNumber = (value: string | number): number =>
  typeof value === 'string' ? Number(value.replace(/,/g, '')) : value

const calculatePercentage = (inputValue: number) => {
  const initial = parseNumber(props.lastPrice)
  const delta = Math.abs(inputValue - initial)
  const percentage = ((delta * 100) / initial).toFixed(props.precision)
  priceValue.value = inputValue < initial ? `-${percentage}` : `+${percentage}`
  formattedValue.value = `${inputValue < initial ? '-' : '+'} % ${percentage}`
}

const calculatePercentagePrice = (inputValue: number) => {
  const formattedValue = addPercent({ numA: parseNumber(props.lastPrice), numB: inputValue })
  percentagePrice.value = formatNumber({
    num: formattedValue,
    precision: props.precision,
  }).toString()
}

const onInput = (value: string | number) => {
  emit('update:modelValue', value)
  emit('on-input', value)

  const inputValue = parseNumber(value)

  if (value === '') {
    formattedValue.value = `% 0`
    priceValue.value = ''
  } else if (alarmType.value === 'price') {
    calculatePercentage(inputValue)
  } else {
    calculatePercentagePrice(inputValue)
  }
}

const sanitizePercentageInput = (value: string) => {
  const sanitized = value
    .replace(/[^0-9+.,-]/g, '')
    .replace(/(?!^)[+-]|^[.,]/g, '')
    .replace(/([.,]).*?\1/g, '$1')
  const numericValue = parseFloat(sanitized.replace(',', '.'))
  if (numericValue > 25) return '+25'
  if (numericValue < -25) return '-25'
  return sanitized
}

const sanitizeInput = (event: Event) => {
  sliderValue.value = 0

  const target = event.target as HTMLInputElement
  let inputValue = target.value

  inputValue !== value.value && (inputCheck.value = true)

  if (props.alarmDirection === 'price') {
    inputValue = sanitizePriceInput(inputValue)
  } else {
    inputValue = sanitizePercentageInput(inputValue)
  }

  target.value = inputValue
  value.value = inputValue

  onInput(inputValue)
}

const lengthCalc = (value: string | number): number => {
  if (typeof value !== 'string' || value.length === 0) {
    return 0
  }

  let length = value.length
  const dotCommaCount = (value.match(/[.,]/g) || []).length
  length -= 0.5 * dotCommaCount

  return length
}

const handleSliderValueChange = (newVal: number, oldVal: number) => {
  if (newVal !== oldVal) {
    inputCheck.value = true
    value.value = newVal > 0 ? `+${newVal}` : newVal.toString()
    calculatePercentagePrice(newVal)
  }
}

const handleModelValueChange = (newVal: string | number, oldVal: string | number) => {
  if (newVal !== oldVal) {
    inputCheck.value = true
    value.value = newVal.toString()
  }
}

const handleAlarmDirectionChange = (newVal: string, oldVal: string, lastPriceVal: string) => {
  if (newVal !== oldVal && newVal !== 'price') {
    sliderValue.value = 0
    priceValue.value = ''
    formattedValue.value = '% 0'
    percentagePrice.value = lastPriceVal
  }

  if (newVal !== oldVal) {
    inputCheck.value = false
  }
}

const handleLastPriceChange = (newVal: string, oldVal: string) => {
  if (newVal !== oldVal && !inputCheck.value) {
    if (alarmType.value !== 'price') {
      percentagePrice.value = newVal
    } else {
      value.value = newVal
    }
  }
}

watch(
  () => sliderValue.value,
  (newSliderValue, oldSliderValue) => {
    handleSliderValueChange(newSliderValue, oldSliderValue)
  },
)

watch(
  () => props.modelValue,
  (newModelValue, oldModelValue) => {
    handleModelValueChange(newModelValue, oldModelValue)
  },
)

watch(
  () => props.alarmDirection,
  (newAlarmDirection, oldAlarmDirection) => {
    handleAlarmDirectionChange(newAlarmDirection, oldAlarmDirection, props.lastPrice)
  },
)

watch(
  () => props.lastPrice,
  (newLastPrice, oldLastPrice) => {
    handleLastPriceChange(newLastPrice, oldLastPrice)
  },
)

watch(
  () => route.query,
  (newQuery, oldQuery) => {
    if (oldQuery !== newQuery) {
      value.value = props.lastPrice?.toString() || '0'
      percentagePrice.value = props.lastPrice
    }
  },
  { deep: true },
)

onMounted(() => {
  focusInput(props.name)
  formattedValue.value = `% 0`
})
</script>
